import "./App.css";
import logo from "./logo.png";
import { store } from "./state/store";
import { Provider } from "react-redux";
import Game from "./Game";

function App() {
  return (
    <Provider store={store}>
      <div className="App">
        <img className="logo" alt="Logo Monopoly" src={logo} />
        <Game />
      </div>
    </Provider>
  );
}

export default App;
