import { createSlice } from "@reduxjs/toolkit";

/**
 * Initial state
 */
const initialState = {
  tag: null
};

/**
 * Selectors
 */
const get = (state) => {
  return state.tag;
}

/**
 * Reducers
 */
const set = (state, action) => {
  state.tag = action.payload.tag;
}

/**
 * Exports
 */
export const slice = createSlice({
  name: "nfc",
  initialState,
  selectors: {
    get,
  },
  reducers: {
    set,
  },
})

export const actions = slice.actions;
export const selectors = slice.selectors;
export default slice.reducer;