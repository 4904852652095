import { createSlice } from "@reduxjs/toolkit";

/**
 * Initial state
 */
const initialState = {};

/**
 * Selectors
 */
const all = (state) => {
  return state;
}

const get = (uuid) => (state) => {
  return state[uuid];
}

const count = (state) => {
  return Object.keys(state).length;
}

/**
 * Reducers
 */
const add = (state, action) => {
  state[action.payload.uuid] = action.payload.name;
}

const remove = (state, action) => {
  delete state[action.payload.uuid];
}

/**
 * Exports
 */
export const slice = createSlice({
  name: "players",
  initialState,
  selectors: {
    all,
    get,
    count,
  },
  reducers: {
    add,
    remove,
  },
})

export const actions = slice.actions;
export const selectors = slice.selectors;
export default slice.reducer