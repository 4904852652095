import { configureStore } from "@reduxjs/toolkit";

import gameplay from "./slices/gameplay";
import players from "./slices/players";
import transactions from "./slices/transactions";
import nfc from "./slices/nfc";

export const store = configureStore({
  reducer: {
    gameplay,
    players,
    transactions,
    nfc,
  },
})