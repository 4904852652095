import { createSlice } from "@reduxjs/toolkit";

/**
 * Initial state
 */
const initialState = [];

/**
 * Selectors
 */
const summary = (state) => {
  return state.stage;
}

/**
 * Reducers
 */
const add = (state, action) => {
  const { from, to, amount } = action.payload;

  state.push({
    from, to, amount
  })
}

/**
 * Exports
 */
export const slice = createSlice({
  name: "transactions",
  initialState,
  selectors: {
    summary,
  },
  reducers: {
    add,
  },
})

export const actions = slice.actions;
export const selectors = slice.selectors;
export default slice.reducer;