
import { useSelector, useDispatch } from "react-redux";
import { actions, selectors } from "../state/slices/gameplay";

function MenuButton({ stage, children }) {
  const currentStage = useSelector(selectors.get);
  const dispatch = useDispatch();
  const changeStage = (stage) => dispatch(actions.set({ stage }));

  return (
    <button onClick={() => changeStage(stage)} className={currentStage === stage ? "highlight" : ""}>{children}</button>
  );
}

export default MenuButton;
