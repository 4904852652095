import { useState } from "react";
import { useDispatch } from "react-redux";

import { actions } from "../state/slices/nfc";

const withNfc = (Component) => () => {
  const [listen, setListen] = useState(false);
  const dispatch = useDispatch();

  async function handleStartListen() {
    if (typeof window.NDEFReader === "undefined") {
      alert("NFC is unsupported");
      return;
    }

    try {
      const ndef = new window.NDEFReader();
      await ndef.scan();

      setListen(true);

      ndef.addEventListener("reading", ({ serialNumber }) => {
        const tag = serialNumber;

        dispatch(actions.set({ tag }));
      });
    } catch (error) {
      /** Do nothing */
    }
  }

  if (!listen) {
    return (
      <button onClick={handleStartListen}>Start NFC</button>
    );
  }

  return <Component />;
}

export default withNfc;