import { createSlice } from "@reduxjs/toolkit";

/**
 * Initial state
 */
export const GameplayStage = {
  initial: "initial",
  players: "players",
  gameplay: "gameplay",
  end: "end"
};

const initialState = {
  stage: GameplayStage.initial
};

/**
 * Selectors
 */
const get = (state) => {
  return state.stage;
}

/**
 * Reducers
 */
const set = (state, action) => {
  state.stage = action.payload.stage;
}

/**
 * Exports
 */
export const slice = createSlice({
  name: "gameplay",
  initialState,
  selectors: {
    get,
  },
  reducers: {
    set,
  },
})

export const actions = slice.actions;
export const selectors = slice.selectors;
export default slice.reducer;