import { useSelector } from "react-redux";
import withNfc from "./hoc/withNfc";

import Players from "./components/Players";
import Gameplay from "./components/Gameplay";

import { selectors as playersSelectors } from "./state/slices/players";
import { selectors as gameplaySelectors, GameplayStage } from "./state/slices/gameplay";
import MenuButton from "./components/MenuButton";

function Game() {
  const count = useSelector(playersSelectors.count)
  const stage = useSelector(gameplaySelectors.get);

  return (
    <>
      <div>
        <MenuButton stage={GameplayStage.players}>Gracze ({count})</MenuButton>
        <MenuButton stage={GameplayStage.gameplay}>Rozgrywka</MenuButton>
      </div>
      <div>
        {stage === GameplayStage.players && <Players />}
        {stage === GameplayStage.gameplay && <Gameplay />}
      </div>
    </>
  );
}

export default withNfc(Game);
