import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { actions, selectors } from "../state/slices/players";
import { selectors as nfcSelectors } from "../state/slices/nfc";

function Players() {
  const [name, setName] = useState("");

  const uuid = useSelector(nfcSelectors.get);
  const players = useSelector(selectors.all);
  const dispatch = useDispatch();

  function handleInput(event) {
    setName(event.target.value);
  }

  function handleAddPlayer() {
    if (uuid) {
      dispatch(actions.add({ uuid, name }));
      setName("");
    }
  }

  function handleFactoryRemovePlayer(uuid) {
    return () => dispatch(actions.remove({ uuid }));
  }

  return (
    <>
      <h3>Lista graczy</h3>
      {
        Object.keys(players).map(
          (uuid) => <div key={uuid}><input readonly value={`${uuid}: ${players[uuid]}`} /> <button onClick={handleFactoryRemovePlayer(uuid)}>X</button></div>
        )
      }
      <div>
        <h3>Dodawanie gracza</h3>
        <input value={name} onChange={handleInput} placeholder="Nazwa gracza" />
        <button onClick={handleAddPlayer}>+</button>
      </div>
    </>
  );
}

export default Players;
