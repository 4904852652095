import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { actions, selectors } from "../state/slices/players";
import { selectors as nfcSelectors } from "../state/slices/nfc";

function Gameplay() {
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const [amount, setAmount] = useState(0);

  const uuid = useSelector(nfcSelectors.get);
  const players = useSelector(selectors.all);
  const dispatch = useDispatch();

  function handleInput(event) {
    setAmount(event.target.value);
  }

  function handleTransaction(uuid) {
    return () => dispatch(actions.remove({ uuid }));
  }

  return (
    <>
      <h3>Stan kont</h3>
      {
        Object.keys(players).map(
          (uuid) => <div key={uuid}><input readonly value={`${uuid}: ${players[uuid]}`} /></div>
        )
      }
      <div>
        <h3>Przelew</h3>
        <input type="number" value={amount} onChange={handleInput} placeholder="Kwota" />
        <br/>
        {from} => {to}
        <br/>
        <button onClick={handleTransaction}>Wykonaj przelew</button>
      </div>
    </>
  );
}

export default Gameplay;
